/** @format */

@import url("https://fonts.googleapis.com/css2?family=Alef&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

body {
  font-family: "Arimo", sans-serif !important;
  background-color: #f3f4f6!important;
}

.container {
  padding: 20px;
}

.title {
  font-size: 20px;
  font-weight: bold;
}
a.flex {
  color: #2d2d2d;
}

span.ant-switch-inner {
  font-size: 17px !important;
  width: 100px;
}

button.ant-switch.ant-switch-checked {
  background: #9191ff;
}

button.ant-switch.ant-switch-checked,
.ant-switch {
  height: 26px !important;
  width: 200px;
}

button.ant-switch {
  background: #fda56b;
}

.ant-switch-handle {
  margin-top: 7px !important;
  width: 18px;
  height: 18px;
}

.slider-right {
  width: 196px;
  margin-right: 29px;
}

.ant-switch-checked .ant-switch-handle:before {
  background: #686363;
}

.ant-switch-checked {
  background: #9b9bff;
}

.fixed-scree {
  background: #483e3ed1;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 999;
  top: 0px;
}

.demantion {
  width: 100%;
  height: 400px;
}

.messur.flex {
  margin-top: 20px;
}

.ant-modal-body {
  padding: 20px !important;
}

.messur .title {
  font-size: 18px;
}

.messur .loc {
  font-size: 18px;
  color: #0e0d0d;
  margin: -9px 20px;
  font-weight: bold;
  justify-content: normal;
  text-align: left;
  direction: ltr;
  gap: 19px;
}

.alert_add.marginTop20 {
  width: 400px;
  border: 1px solid #ff6700;
  position: fixed;
  z-index: 9999;
  right: 35vw;
  top: 34vh;
  background: #fff;
  color: #151515;
  flex-direction: column;
  gap: 0px !important;
  border-radius: 10px;
}
.content-page {
  padding: 16px;
}

.smallview {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 32px;
  padding: 0px 20px 10px 20px;
}

button.gm-ui-hover-effect {
  display: none !important;
}

.smallview button {
  display: none !important;
}

.small .pop-bar {
  min-width: 70px !important;
  height: 65px !important;
  padding: 0px 7px !important;
}

.pop-bar {
  min-width: 380px !important;
}

.content-wrapper {
  width: calc(100% - 229px);
  margin-right: 229px;
  position: static;
  background: #f3f4f6;
  height: 100vh;
}

.box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e9e9ef;
  border-radius: 0.25rem;
  position: relative;
  border: 1px solid #cebcbc;
}

.ant-popover-buttons button {
  font-size: 10px !important;
  padding: 0px 20px !important;
  height: 25px !important;
}

.ant-popover-buttons {
  display: flex !important;
  width: 140px !important;
  margin: 0 auto !important;
}

th {
  text-align: right !important;
}

td.ant-table-cell {
  border-left: 1px solid #9ca3af;
  border-bottom: 1px solid #9ca3af;
}

.table-data .ant-table-cell svg {
  margin: 0 auto;
}

th.ant-table-cell {
  font-size: 14px;
  color: #6b6b70b8 !important;
}

button {
  color: #fff;
  background-color: #2d2d2dfa;
  padding: 4px 20px;
  border-radius: 5px;
}
.title {
  font-size: 22px;
  line-height: 30px;
  color: #000000;
}

.ant-tag-magenta {
  background: #939395;
  border: navajowhite;
  color: #fff;
}
.bg-light {
  background-color: #d7d6d6;
}

.row-data {
  background: #f3f3f359;
  padding: 15px 15px;
}

.desktop {
  display: none;
}

.title-2 {
  font-size: 17px;
}

.ant-dropdown-menu .flex.menu-title {
  justify-content: end;
  text-align: left;
  background: #ff670040;
  padding: 6px;
  font-size: 12px;
  font-weight: 300;
}

.ant-dropdown-menu .sub-menu {
  margin: 16px;
}

.ant-dropdown-menu a.flex {
  justify-content: end;
}

.justify-centered {
  justify-content: center;
}

.ant-dropdown-menu ul.ant-dropdown-menu {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  padding: 21px !important;
}

.card-mixer-info .ant-card-body {
  width: 100%;
}

.center_loading {
  position: absolute;
  top: 40vh;
  left: 0;
  right: 0;
  color: #000000;
}

.center_loading i.ant-spin-dot-item {
  background: #ff6700 !important;
}

.chart-con .ant-tabs-content.ant-tabs-content-left {
  width: 1200px;
  height: 300px;
}

@media screen and (max-width: 600px) {
  .ant-select-selector {
    height: 47px !important;
  }

  span.ant-select-selection-item {
    padding-top: 9px !important;
  }

  .ant-col.ant-form-item-label label {
    font-size: 21px;
  }

  .ant-col.ant-form-item-label {
    text-align: left !important;
    justify-content: left;
    display: flex;
  }

  .sidebar {
    display: none;
    z-index: 9999;
    .logo {
      display: none;
    }

    .fotter-data {
      position: static !important;
    }

    svg.clos-bar {
      display: none;
    }
  }

  .box {
    overflow-x: scroll;
  }
  .content-wrapper.flex1 {
    width: 100% !important;
    right: inherit;
  }

  .flex.gap20.marginHor50 {
    flex-direction: column;
  }

  .content-wrapper.flex1 {
    width: 100% !important;
    right: inherit;
    margin: inherit !important;
    height: 100% !important;
  }

  td.ant-table-cell,
  th {
    height: 20px !important;
    padding: 2px 24px !important;
    text-align: right !important;
  }

  .header.flex {
    justify-content: space-between;
  }
}

// #root{
//   height: 100vh;
//     overflow: hidden;
// }

.csv-download {
  display: flex;
  justify-content: end;
  align-items: center;
  width: fit-content;
  place-self: center;
  height: 38px;
  font-size: 14px;
}

.deleviry th.ant-table-cell:nth-child(9) {
  width: 128px;
}

.deleviry th.ant-table-cell:nth-child(10) {
  width: 50px;
}

.ant-picker-panel-container {
  background: #fff !important;

  direction: ltr;
}

.ant-picker-cell-inner {
  color: #120e0e;
}

.ant-picker-header-view {
  color: #120e0e;
}

.ant-picker-time-panel-cell-inner {
  color: #120e0e !important;
}

.ant-picker-cell-in-range .ant-picker-cell-inner {
  color: #ff6700;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover,
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  color: #ff6700 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
  color: white;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #ff6700;
}

input#rc_select_61 {
  color: #171515!important;
}

.ant-select.ant-select-auto-complete.ant-select-single.ant-select-show-search {
  border: 1px solid;
  height: 47px;
  border-radius: 8px;
  border-color: #949496;
}

.ant-select-auto-complete input {
  color: rgb(109, 109, 112) !important;
  padding: 20px 0px!important;
}

@media screen and (max-width: 600px) {
  .mobile-col {
    flex-direction: column;
  }

 
  .doctorfilter {
    display: none !important;
  }
  .show .doctorfilter {
    display: block !important;
  }

  .download_csv {
    margin: 0 auto;
    display: block;
    justify-content: center;
    display: flex;
  }

  .flex.page-title.gap20 {
    height: 20px !important;
  }

  .flex.mobile-flex {
    flex-direction: column;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .desktop {
    display: block;
  }

  .menu-items {
    width: 90vw;
    padding: 20px;
    font-size: 20px;
  }
}

.he {
  .ant-picker.ant-picker-range {
    direction: rtl !important;
  }

  .ant-select-selection-item {
    text-align: right !important;
  }

  .ant-picker-date-panel .button {
    color: #fffefe !important;
  }
  
  .total {
    left: 8vw!important;
  }
}

