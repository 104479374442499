/**
 * @format
 * @ormat
 */

// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Barlow", sans-serif;
}
.app.en {
  direction: ltr;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e5e7eb !important;
  color: #2d2d2d !important;
}

.ant-picker-cell-in-range .ant-picker-cell-inner {
  color: #222 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  background: transparent !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
  color: #222 !important;
  background: #ff6700 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #222 !important;
  font-weight: bold !important;
}

.ant-picker-header {
  background: #d1d5db !important;
}

.ant-picker-header button {
  color: #ff6700 !important;
}

.ant-picker-header-view button {
  color: #120e0e !important;
}

.gm-ui-hover-effect {
  position: absolute !important;
  right: 0px;
  width: 100% !important;
  z-index: 9999 !important;
  height: 44px !important;
  top: 5px !important;
}

button.ant-btn.ant-btn-primary.ant-btn-sm {
  background: #ff6700;
  border: none;
  color: #222;
  height: 35px;
}

.gm-ui-hover-effect > span {
  position: absolute;
  right: 0px;
  font-size: 27px !important;
  background-color: #ff6700;
  width: 30px !important;
  height: 30px !important;
}
.map-view{
  height: 100vh;
}

.map-view svg {
  width: 28px!important;
}

.gm-style-iw-ch {
  padding: 0px;
}

@media screen and (max-width: 700px) {
  .gm-style-iw .content-page.pop-bar {
    max-width: 100%;
    min-width: inherit !important;
  }
}



.he {
  .content-wrapper.flex1 {
    margin-right: 271px;
  }


  .ant-select-selector {
      direction: rtl;
  }

  .ant-select-item {
    direction: rtl !important;
    text-align: right !important;
  }

  .total{
    width: calc(100% - 225px)!important;
  }

  .bar-close {
    margin-right:0%!important;
  }

  .bar-close .full_data {
    width: 90vw!important;
  }

  .recharts-wrapper {
      direction: rtl;
  }

  .unloading_mixer {
    left: 0px;
    right: inherit !important;
  }

  .sensor_group_1 {
    right: inherit !important;
    left: 0;
  }

  .ant-table-cell {
    text-align: right;
  }

  .addon-add {
    left: -17px;
    right: inherit !important;
  }

  .sensor_status_item {
    padding: 0px !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 32px;
  }



  @media screen and (max-width: 600px) {
    .bold.t1{
      font-size: 19px!important;
    }
  }
}

.en {
  label {
    direction: rtl;
  }

  .content-wrapper {
    margin-left: 245px;
    margin-right: inherit;
  }

  li.ant-pagination-options {
    margin-right: 20px;
  }

  .bar-close {
    margin: 0 !important;
  }

  .bar-close .full_data {
    width: 95vw !important;
  }

  .ant-pagination svg {
    transform: rotate(180deg) !important;
  }

  svg.clos-bar.single {
    position: fixed;
    bottom: 10%;
    z-index: 99;
    left: -14px;
    transform: rotateY(180deg);
  }

  th.ant-table-cell {
    padding: 10px !important;
    text-align: left !important;
    border: none;
    border-left: 1px solid #9ca3af;
    border-bottom: 1px solid #9ca3af;
    // min-width: 187px;
  }

  td.ant-table-cell {
    border-left: 1px solid #9ca3af;
    border-bottom: 1px solid #9ca3af;
  }

  .setting-page {
    left: inherit !important;
    right: 0;
    top: 29px !important;
  }
  .header.flex {
    direction: rtl;
  }

  .sub-menu {
    padding-left: 38px;
    padding-right: inherit;
  }

  .sensor_status_item {
    padding: 14px 23px 0px 0px !important;
  }

  .gm-style-iw.gm-style-iw-c {
    padding: 0px;
  }

  .gm-style-iw-d {
    padding: 0px !important;
    margin: 0 auto;
    display: contents;
  }

  .menu-items {
    svg {
      margin-right: 10px;
    }
  }

  .ant-select-item {
    text-align: left !important;
  }
}

label {
  direction: ltr;
}

.map-wrapper {
  padding: 20px;
}

.ant-form-item-label > label::after {
  display: none;
}

.ant-table {
  font-size: 20px !important;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none;
}
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    @media screen and (max-width: 1100px) {
      font-size: 18px !important;
    }
    &:first-child {
      margin-bottom: 2px !important;
    }
  }
}

.change-placeholder {
  font-size: 20px;

  &::placeholder {
    font-size: 20px !important;
  }
}
h5 {
  margin: 0 !important;
  cursor: pointer;
  font: normal normal 600 25px/24px;
  font-size: 25px;
}
.ant-btn:hover,
.ant-btn:focus {
  background-color: transparent;
  border-color: currentColor;
}
.ant-btn {
  padding-top: 0;
}
th.ant-table-cell {
  font-size: 20px;
}

.how-much {
  text-align: left !important;
}

.filter-grid.doctorfilter {
  grid: 45px/16% 40% 37% 27% 27% !important;
}

.ant-table-thead > tr > th {
  background: #fff;
  border-color: #d0d1f0;
  font-weight: bold;
  color: #2d2d2d !important;
}
.ant-table-thead > tr > th {
  padding: 5px 16px;
  border: 1px solid #bdbdbd;
}
.ant-checkbox-inner {
  width: 35px;
  height: 35px;
  border-radius: 4px;
  @media screen and (max-width: 1400px) {
    height: 25px;
    width: 25px;
  }
}
button.small-text-button {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.ant-table-pagination.ant-pagination {
  align-items: center;
}

.ant-pagination-item-active a {
  background: #ff6700 !important;
  border-radius: 4px;
}
.ant-checkbox-inner::after {
  width: 8.714286px;
  height: 16.142857px;
  top: 43%;
  left: 9px;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 4px solid #fff;
  border-top: 0;
  border-left: 0;
}

.table-general .ant-table-thead > tr > th {
  &:last-child {
    border-bottom: none !important;
  }
}
.client-management-table
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table
  .ant-table-container
  .ant-table-content {
  table {
    thead {
      tr {
        th {
          border-bottom: 0;
          padding: 0 16px;
        }
      }
    }
  }
}

.add-icon {
  cursor: pointer;
}
.back-icon {
  cursor: pointer;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;*/
  font-family: "Alef", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: white;
  background-color: white;
}

.helvetica-font {
  font-family: "Helvetica Neue";
}
.avenir-book {
  font-family: "Avenir Book" !important;
  font-weight: normal;
}
* {
  outline: none;
  box-sizing: border-box;
}
.ant-modal-content {
  border-radius: 5px;
}
.ant-select-item-group {
  background: #000aff !important;
  color: #fff;
}
.ant-select-dropdown .ant-select-item.white-option {
  border-bottom: 1px solid #646d64;
  color: #646d64;
  background: #fff;
  font-weight: normal;
}

.ant-select-item {
  font-size: 20px;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    cursor: pointer;
    &:not(:first-child) {
      margin-right: 20px;
    }
  }
}
.ant-modal-body {
  padding: 0 !important;
  border-radius: 10px !important;
  .border {
    width: 100%;
    border-radius: 10px;
    background-color: #c1c8d8;
    padding: 35px;
    border: 1px solid #707070;
  }
  .content-white-2 {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px 50px 20px 0;

    @media screen and (max-width: 1000px) {
      padding: 20px 20px 20px 0;
    }
    @media screen and (max-width: 900px) {
      padding: 20px 20px 20px 20px;
    }
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    .width-full {
      width: 100%;
      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
      .edit-section {
        width: 70%;
        @media screen and (max-width: 900px) {
          width: 100%;
        }
        & > div {
          textarea {
            border-radius: 10px;
            outline: #000aff;
            padding: 20px;
            &.text-area-dark {
              background-color: #e7e8ed;
            }
            &::placeholder {
              color: #707070;
              font-size: 16px;
            }
          }
          &:not(:last-child) {
            margin-bottom: 45px;
          }
        }
      }
      .button-section {
        width: 30%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 80px;
        button {
          border-radius: 5px;
        }
        @media screen and (max-width: 900px) {
          width: 100%;
          padding-bottom: 0;
          padding-top: 40px;
        }
      }
    }
    .icon {
      font-size: 50px;
      color: #000aff;
    }
    p {
      color: #646d82;
      font-weight: 700;
      font-size: 16px;
      margin-top: 15px;
      text-align: right;
    }
    .buttons {
      display: flex;
      justify-content: space-around;
      width: 80%;
      button {
        width: 40%;
        border-radius: 5px;
      }
    }
  }

  svg.injected-svg {
    width: 15px;
  }
  .content-white {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    border-radius: 10px;
    .icon {
      font-size: 50px;
      color: #000aff;
    }
    p {
      color: #000aff;
      font-size: 24px;
      margin-top: 15px;
      &.large {
        text-align: center;
        font-size: 34px;
        padding: 0 80px;
      }
    }
    .buttons {
      display: flex;
      justify-content: space-around;
      width: 80%;
      button {
        width: 40%;
        border-radius: 5px;
      }
    }
  }
}
img {
  max-width: 100%;
}

.ant-input:focus,
.ant-input-focused {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-breadcrumb a,
.ant-breadcrumb > span:last-child a {
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.wapperContent {
  display: inline-block;
  width: 100%;
  overflow: auto;
}

.page-wrapper {
  width: 100%;
  padding: 0px 20px;
}

.marginTop {
  margin-top: 90px;
}

.greencolor {
  color: #6cbd5a;
}

.bluecolor {
  color: #024d79;
}

.graycolor {
  color: #737373;
}

.grayColor {
  color: #828282;
}
.lightgraycolor {
  color: #c1c8d8;
}
.fontweight {
  font-weight: bold;
}

.fontweight400 {
  font-weight: 400;
}

.fontweight500 {
  font-weight: 500;
}

.fontweight600 {
  font-weight: 600;
}

.fontweight700 {
  font-weight: 700;
}

.shdowBox {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

.pointer {
  cursor: pointer;
}

.pointer:active {
  opacity: 0.8;
}

.padding15 {
  padding: 15px;
}

/*button style */

.btnStyle {
  background: #55b33e;
  color: var(--whiteColoer);
  border: none;
  border-radius: 15px;
  padding: 10px 15px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 14px;
  max-width: fit-content;
  border-radius: 25.5px;
  padding: 10px 26px;
  margin: 0 auto;
  display: block;
  white-space: normal;
}

.btnStyle2 {
  background: #5eb870;
  color: var(--whiteColoer);
  border: none;
  border-radius: 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 26px;
  margin: 0 auto;
  display: block;
  white-space: normal;
}

.btnStyle2:hover,
.btnStyle2:focus {
  background: #f88602;
  color: #ffffff !important;
}

.btn-orange {
  background: #f88602;
  color: #ffffff;
  border: 1px solid #f88602;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 40px;
  display: block;
  display: inline-block;
  white-space: normal;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
}

.btn-orange:hover,
.btn-orange:focus {
  background: #ffffff;
  color: #f88602;
  border: 1px solid #ffffff;
}

.btn-orange-border {
  background: transparent;
  color: #f88602;
  border: 1px solid #f88602;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 40px;
  display: inline-block;
  white-space: normal;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
}

.btn-orange-border:hover,
.btn-orange-border:focus {
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.btn-red {
  background: #f05050;
  color: #ffffff;
  border: 1px solid #f05050;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 40px;
  display: block;
  display: inline-block;
  white-space: normal;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
}

.btn-red:hover,
.btn-red:focus {
  background: #ffffff;
  color: #f05050;
  border: 1px solid #ffffff;
}

.btn-red2 {
  background: #fd4d01;
  color: #ffffff;
  border: 1px solid #fd4d01;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 40px;
  display: block;
  display: inline-block;
  white-space: normal;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
}

.btn-red2:hover,
.btn-red2:focus {
  background: #ffffff;
  color: #fd4d01;
  border: 1px solid #ffffff;
}

.btn-red-border {
  background: transparent;
  color: #f05050;
  border: 1px solid #f05050;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 40px;
  display: inline-block;
  white-space: normal;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
}

.btn-red-border:hover,
.btn-red-border:focus {
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.btn-red-border2 {
  background: transparent;
  color: #fd4d01;
  border: 1px solid #fd4d01;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 40px;
  display: inline-block;
  white-space: normal;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12);
}

.btn-red-border2:hover,
.btn-red-border2:focus {
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.boxTotal {
  padding: 5px;
  margin: 10px 0;
  background: #eeeeee;
  display: block;
  width: 250px;
  border-radius: 5px;
  color: black;
}

.link {
  cursor: pointer;
  display: inline-block;
}

.link:hover {
  opacity: 0.6;
}

.paddingP {
  padding: 5px;
}

.colorWhite {
  color: white;
}

.btnGreen {
  display: block;
  margin: auto;
  padding: 11px;
  border-radius: 8px;
  min-width: 100px;
  background: #56b247;
  color: white;
}

button {
  cursor: pointer;
}

.text-decoration-through {
  text-decoration: line-through;
}

.text-uppercase {
  text-transform: uppercase;
}

.letter-spacing0 {
  letter-spacing: 0px;
}

/* Track */

.scrollbar::-webkit-scrollbar-track {
  background: #88888829;
  border-radius: 72px;
}

.scrollbar::-webkit-scrollbar,
.height-scroll::-webkit-scrollbar {
  width: 7px;
  height: 6px;
  border-radius: 72px;
}

/* Handle */

.scrollbar::-webkit-scrollbar-thumb {
  background: #0a4e7b39;
}

/* Handle on hover */

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #55555555;
}

.pointer {
  cursor: pointer;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  border-right: 1px solid #d0d0d0;
}

.menu-modal button.ant-modal-close {
  position: static;
  margin: 0 auto;
  width: 49px;
  display: block;
}

.content {
  min-height: calc(100vh - 95px);
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.bottom {
  position: absolute;
  bottom: 0px;
}

.boxFedback {
  height: 35px;
  left: 0px;
  top: -28.34px;
  background: #72c6d1 !important;
  border-radius: 25px;
  border-radius: 25px !important;
  width: 144.87px !important;
  position: relative;
}

.boxFedbackM {
  height: 35px;
  left: 114px;
  top: -6.34px;
  background: #72c6d1 !important;
  border-radius: 25px;
  border-radius: 25px !important;
  width: 144.87px !important;
  position: relative;
}

.right0 {
  right: 0;
}

.scroll {
  overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
  width: 4px !important;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #5edc8933;
  border-radius: 10px;
}

/* colors */

.white {
  color: #fff !important;
}
.dark-blue {
  color: #646d82 !important;
}
.off-white {
  color: #cccccc !important;
}

.black {
  color: #333333 !important;
}

.black2 {
  color: #000000 !important;
}

.yellow {
  color: #fecd54 !important;
}

.yellow2 {
  color: #ffd147 !important;
}

.black {
  color: #282d32 !important;
}

.gray {
  color: #3b4149 !important;
}

.gray1 {
  color: #535353 !important;
}

.gray2 {
  color: #90a0b7 !important;
}

.gray3 {
  color: #949494 !important;
}

.grayText {
  color: #90a0b7 !important;
}

.blue {
  color: #224653 !important;
}

.white {
  color: #fff !important;
}

.blueT {
  color: #2a689457 !important;
}

.blue2 {
  color: #000aff !important;
}

.red {
  color: #ff8585 !important;
}

.red2 {
  color: #cc4c4c !important;
}

.red3 {
  color: #f05050 !important;
}

.red4 {
  color: #fd4d01 !important;
}

.orange {
  color: #fc9f4f !important;
}

.green {
  color: #2cd889 !important;
}

.cyan {
  color: #61ebf7 !important;
}
.purpal {
  color: #ac1afa !important;
}

.b-gray {
  background: #3b4149 !important;
}

.b-gray1 {
  background: #535353 !important;
}

.b-gray2 {
  background: #90a0b7 !important;
}

.b-gray3 {
  background: #949494 !important;
}

.b-grayText {
  background: #90a0b7 !important;
}

.b-blue {
  background: #224653 !important;
}

.b-blueT {
  background: #2a689457 !important;
}

.b-blue2 {
  background: #000aff !important;
}

.b-red {
  background: #ff8585 !important;
}

.b-red2 {
  background: #cc4c4c !important;
}

.b-red3 {
  background: #f05050 !important;
}

.b-red4 {
  background: #fd4d01 !important;
}

.b-orange {
  background: #fc9f4f !important;
}

.b-green {
  background: #2cd889 !important;
}

.b-cyan {
  background: #61ebf7 !important;
}
.b-purpal {
  background: #ac1afa !important;
}

.pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

/* background */

.transparent {
  background-color: transparent !important;
}

.b-pirples {
  background: #e1c7b9 !important;
}

.b-white {
  background: #fff !important;
}

.b-off-white {
  background: #faf1ed !important;
}

.b-gray {
  background: #646d82 !important;
}

.b-gray-2 {
  background: #626979 !important;
}

.b-grayM {
  background: #494f57 !important;
}

.b-grayM2 {
  background: #818286 !important;
}

.b-grayM3 {
  background: #484848 !important;
}

.b-light-gray {
  background: #cccccc !important;
}

.b-yellow {
  background: #ffd147 !important;
}

.b-yellow2 {
  background: #ffd14717 !important;
}

.b-blue {
  background: #224653 !important;
}

.b-blue2 {
  background-color: #ff6700 !important;
}

.b-blueT {
  background: #2a689457 !important;
}

.b-pirple {
  background: #f8f2ff !important;
}

.b-black {
  background: #17181b !important;
}

.b-black1 {
  background: #121212 !important;
}

.b-black2 {
  background: #000000 !important;
}

.b-black3 {
  background: #333333 !important;
}

.b-red {
  background: #ff8585 !important;
}

.b-red1 {
  background: #f05050 !important;
}

.b-red2 {
  background: #cc4c4c !important;
}

.b-red4 {
  background: #fd4d01 !important;
}

.b-green {
  background: #5edc89 !important;
}

.b-orange {
  background: #f88602 !important;
}
.b-cream {
  background: #f3eade !important;
}

/* border */

.br-healine {
  display: inline;
  padding: 0 0 0 36px;
  border-bottom: 3px solid;
}

.br-box {
  border: 3px solid;
}

.no-border {
  border: none !important;
}
.br-white {
  border-color: #fff !important;
}
.br-gray {
  border-color: #3b4149 !important;
}

.br-gray2 {
  border-color: #767676 !important;
}

.br-gray3 {
  border-color: #949494 !important;
}

.br-grayM {
  border-color: #928c8c !important;
}

.br-yellow2 {
  border-color: #ffd14717 !important;
}

.br-blue {
  border-color: #224653 !important;
}

.br-blue2 {
  border-color: #ff6700 !important;
}

.br-blueT {
  border-color: #2a689457 !important;
}

.br-black {
  border-color: #00000057 !important;
}

.br-red {
  border-color: #ff8585 !important;
}

.br-red2 {
  border-color: #cc4c4c !important;
}

.br-orange {
  border-color: #f88602 !important;
}

.br-priple {
  border-color: #e3cbfd !important;
}

.br-green {
  border-color: #5edc89 !important;
}

.radius {
  border-radius: 6px !important;
}

.radius10 {
  border-radius: 10px !important;
}
.radius15 {
  border-radius: 15px !important;
}

.circle {
  border-radius: 50px !important;
}

.dash {
  border: 1px dashed;
}

.br {
  border: 1px solid;
}

.br-left {
  border-left: 1px solid;
}

.br-right {
  border-right: 1px solid;
}

.br-bottem {
  border-bottom: 1px solid;
}

.br-top {
  border-top: 1px solid;
}

.border-radius-10 {
  border-radius: 10px;
}

/* width */

.full {
  width: 100% !important;
}

.height-100 {
  height: 100% !important;
}

.full-height {
  height: 100vh !important;
}

.padding-page {
  padding: 80px 0px 0px 0px;
}

.padding-page2 {
  padding: 60px 0px 0px 0px;
}

.col-6 {
  width: 50% !important;
}

/* image */

.imageFull {
  max-height: 300px;
  object-fit: cover;
  width: 100%;
}

.imageHalf {
  max-height: 150px;
  width: 100%;
  object-fit: cover;
}

/* border */

.no-border {
  border: none !important;
}

/* float */

.fLeft {
  float: left !important;
}

.fRight {
  float: right !important;
}

/* button */

.buttonSquere {
  width: 50px;
}

.buttonSquere45 {
  width: 45px;
  height: 45px;
}
.buttonSquere56 {
  width: 56px;
  height: 56px;
}

.buttonSquere32 {
  width: 32px;
  height: 32px;
}

.auto {
  width: auto;
}

.ant-select {
  margin-top: 0px !important;
}

/* padding */

.no-padding {
  padding: 0px !important;
}

.padding5 {
  padding: 5px !important;
}

.padding8 {
  padding: 8px !important;
}

.padding10 {
  padding: 10px !important;
}

.padding15 {
  padding: 15px !important;
}

.paddingVer20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.paddingVer25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.paddingVer30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.paddingVer40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.padding40 {
  padding: 40px !important;
}

.padding60 {
  padding: 60px !important;
}

.padding100 {
  padding: 100px 100px 200px 100px !important;
}

.paddingVer40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.paddingVer10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.paddingVer15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.paddingHor5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.paddingHor8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.paddingHor10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.paddingHor15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.paddingHor20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.paddingHor30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.paddingHor150 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.paddingVer100 {
  padding-right: 100px;
  padding-left: 100px;
}

.paddingLeft30 {
  padding-left: 30px !important;
}
.paddingRight10 {
  padding-right: 10px !important;
}

.padding20 {
  padding: 20px !important;
}

.padding25 {
  padding: 25px !important;
}

.paddingRight20 {
  padding-right: 20px !important;
}

.paddingTop5 {
  padding-top: 5px !important;
}

.paddingTop10 {
  padding-top: 10px !important;
}

.paddingTop15 {
  padding-top: 15px !important;
}

.paddingTop20 {
  padding-top: 20px !important;
}

.paddingTop40 {
  padding-top: 40px !important;
}
.paddingTop50 {
  padding-top: 50px !important;
}

.paddingTop60 {
  padding-top: 60px !important;
}

.paddingRight15 {
  padding-right: 15px !important;
}
.paddingRight5 {
  padding-right: 5px !important;
}
.paddingRight30 {
  padding-right: 30px !important;
}
.paddingRight40 {
  padding-right: 40px !important;
}
.paddingRight50 {
  padding-right: 50px !important;
}
.paddingRight60 {
  padding-right: 60px !important;
}
.paddingBottom10 {
  padding-bottom: 10px !important;
}

.paddingBottom20 {
  padding-bottom: 20px !important;
}

.paddingBottom30 {
  padding-bottom: 30px !important;
}
.paddingBottom56 {
  padding-bottom: 56px !important;
}

.paddingLeft10 {
  padding-left: 10px !important;
}

.paddingLeft20 {
  padding-left: 20px !important;
}

.paddingLeft30 {
  padding-left: 30px !important;
}

.paddingLeft40 {
  padding-left: 40px !important;
}

.paddingLeft50 {
  padding-left: 50px !important;
}
.paddingLeft60 {
  padding-left: 60px !important;
}
.paddingRight50 {
  padding-right: 50px !important;
}

/* margin */

.margin5 {
  margin: 5px;
}

.margin10 {
  margin: 10px;
}
.margin30 {
  margin: 30px;
}

.margin7 {
  margin: 7px;
}

.marginCenter {
  margin: 0 auto !important;
}

.marginBottom0 {
  margin-bottom: 0px !important;
}

.marginBottom5 {
  margin-bottom: 5px !important;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}

.marginBottom15 {
  margin-bottom: 15px !important;
}

.marginBottom20 {
  margin-bottom: 20px !important;
}

.marginBottom30 {
  margin-bottom: 30px !important;
}

.marginBottom40 {
  margin-bottom: 40px !important;
}

.marginBottom50 {
  margin-bottom: 50px !important;
}

.marginBottom80 {
  margin-bottom: 80px !important;
}

.marginBottom100 {
  margin-bottom: 100px !important;
}

.marginBottom150 {
  margin-bottom: 150px !important;
}

.marginTop0 {
  margin-top: 0px !important;
}

.marginTopauto {
  margin-top: auto !important;
}

.marginTop5 {
  margin-top: 5px !important;
}

.marginTop10 {
  margin-top: 10px !important;
}

.marginTop15 {
  margin-top: 15px !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginTop30 {
  margin-top: 30px !important;
}

.marginTop40 {
  margin-top: 40px !important;
}

.marginTop50 {
  margin-top: 50px !important;
}

.marginVer10 {
  margin-left: 10px;
  margin-right: 10px;
}

.marginVer20 {
  margin-left: 20px;
  margin-right: 20px;
}

.marginHor15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.marginHor20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.marginHor30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.marginHor50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.marginHor10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin20 {
  margin: 20px;
}

.margin40 {
  margin: 40px;
}

.marginLeft0 {
  margin-left: 0px !important;
}

.marginLeftauto {
  margin-left: auto;
}

.marginLeft20 {
  margin-left: 20px;
}
.marginLeft100 {
  margin-left: 100px;
}
.marginLeft150 {
  margin-left: 150px;
}
.marginLeft200 {
  margin-left: 280px;
}
.marginLeft180 {
  margin-left: 220px;
}
.image-class {
  width: 50px;
  height: 100px;
}
.marginLeft0 {
  margin-left: 5px;
}
.marginLeft4 {
  margin-left: 4px;
}

.marginLeft5 {
  margin-left: 5px;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginLeft15 {
  margin-left: 15px;
}

.marginRight0 {
  margin-right: 0px !important;
}

.marginRight5 {
  margin-right: 5px !important;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight15 {
  margin-right: 15px;
}

.marginRight20 {
  margin-right: 20px !important;
}

.marginRight30 {
  margin-right: 30px !important;
}

.no-margin {
  margin: 0 !important;
}
.marginRightAuto {
  margin-right: auto !important;
}
.width100 {
  width: 100%;
}
.width100vw {
  width: 100vw;
}
/* font */

.bold {
  font-weight: bold;
}

.b6 {
  font-weight: 600;
}

.fw400 {
  font-weight: 400 !important;
}
.f10 {
  font-size: 10px;
}
.f14 {
  font-size: 14px;
}
.f100 {
  font-size: 100px;
}

.f90 {
  font-size: 90px;
}

.f70 {
  font-size: 70px;
}

.f60 {
  font-size: 60px;
}
.f57 {
  font-size: 57px;
}

.f40 {
  font-size: 40px;
}

.f35 {
  font-size: 35px;
}
.f36 {
  font-size: 36px;
}

.f32 {
  font-size: 32px;
}

.f30 {
  font-size: 30px;
}

.f27 {
  font-size: 27px;
  line-height: normal;
}

.f25 {
  font-size: 25px;
  line-height: normal;
}

.f24 {
  font-size: 24px;
  line-height: normal;
}

.f23 {
  font-size: 23px !important;
}
.f22 {
  font-size: 22px !important;
}
.f21 {
  font-size: 21px !important;
}

.f20 {
  font-size: 20px !important;
}
.f19 {
  font-size: 19px !important;
}
.f18 {
  font-size: 18px !important;
}

.f17 {
  font-size: 17px;
}

.f16 {
  font-size: 16px;
}

.f15 {
  font-size: 15px;
}

.f14 {
  font-size: 14px;
}

.f13 {
  font-size: 13px;
}

.f12 {
  font-size: 12px;
}

.f11 {
  font-size: 11px;
}

.f1 {
  font-size: 25px;
}

.f2 {
  font-size: 20px;
}

.f3 {
  font-size: 18px;
}

.f4 {
  font-size: 14px;
}

.f5 {
  font-size: 12px;
}

.f6 {
  font-size: 10px;
}

.line1 {
  line-height: 1;
}
.line2p5 {
  line-height: 2.5;
}

.link {
  color: #224653;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.underLine {
  text-decoration: underline;
}

.lineThrough {
  text-decoration: line-through;
}

.flexFull {
  align-items: center;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  display: flex !important;
}

.flexVerCenter {
  align-items: center;
}

.flexVerTop {
  align-items: flex-start;
}

.circle-50 {
  width: 50px;
  height: 50px;
}
.flexVerStretch {
  align-items: stretch;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexCol {
  flex-direction: column !important;
}

.flexRow {
  flex-direction: row !important;
}

/* align */

.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

/*transfprm */

.capitalize {
  text-transform: capitalize;
}

/* display */

.show {
  display: block;
}

.contents {
  display: contents;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex !important;
}

.flex1 {
  flex: 1;
}

.flex1 {
  flex: 1;
}

.flexEnd {
  justify-content: flex-end !important;
}

.alignEnd {
  align-self: flex-end !important;
}

.lineHeight0 {
  line-height: 0;
}

.lineHeightDefault {
  line-height: 1.3;
}

.lineHeightNormal {
  line-height: normal;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.flexSpaceBetween {
  justify-content: space-between;
}

.flexColumn {
  flex-direction: column;
}

.flow-root {
  display: flow-root !important;
}

.grid {
  display: grid !important;
}

.grid5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.grid10 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid2 {
  grid-template-columns: 1fr 1fr;
}

.gap40 {
  grid-gap: 40px;
}

.gap20 {
  grid-gap: 20px;
}

.gap75 {
  grid-gap: 75px;
}

.gap120 {
  grid-gap: 120px;
}

.gap15 {
  grid-gap: 15px;
}

.gap5 {
  grid-gap: 5px;
}
.gap10 {
  grid-gap: 10px;
}

.gap30 {
  grid-gap: 30px;
}

.hide {
  display: none !important;
}

.heightAuto {
  height: auto;
}
.height10 {
  height: 10px;
}
.width10 {
  width: 10px;
}
.height150 {
  height: 150px;
}
.height100 {
  height: 100%;
}
.height100vh {
  height: 100vh;
}
.height70vh {
  height: 70vh;
}

.box {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.rtl {
  direction: rtl;
}

.ant-collapse-item {
  direction: rtl;
}

.ant-switch {
  height: 16px;
  line-height: 16px;
  background-color: #cccccc;
}

.ant-switch-handle {
  top: -3px;
  left: 0px;
  width: 22px;
  height: 22px;
  border-radius: 30px;
}

.ant-switch-handle:before {
  background-color: #666666;
  border-radius: 30px;
}

.ant-switch-checked {
  background-color: rgba(253, 77, 0, 0.5);
}

.ant-switch-checked .ant-switch-handle:before {
  background-color: #fd4d00;
}

.ant-breadcrumb > span:last-child,
.ant-breadcrumb-separator {
  color: rgba(255, 255, 255, 1);
}

.ant-breadcrumb-separator {
  margin: 0 5px;
}

.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: inline-block;
}

/* modal */

// .ant-row {
//     display: inline-block !important;
//     margin-right: 95px !important;
// }
.textFilter {
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: right;
  position: absolute;
  color: #224653;
}

.textHeaderCard {
  font-family: Assistant !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 17px !important;
  line-height: 22px !important;
  text-align: right !important;
  color: #224653 !important;
  width: 56%;
  padding-right: 27px;
}

.textCard {
  width: 128px;
  height: 30px;
  margin-top: 20px;
  margin-right: 9.37px;
  font-family: Assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  text-align: right;
  color: #224653;
}

.addressStyle {
  font-family: Assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  text-align: left;
  color: #224653;
}

.ant-modal-body {
  display: flow-root;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-col-4 {
  width: 3.666% !important;
  left: 35px;
}

.ant-slider {
  width: 87px;
}

.ant-slider:hover .ant-slider-track {
  background-color: #ec5118 !important;
}

.ant-slider-track {
  background-color: #ec5118 !important;
  border: solid 2px#ad49345c !important;
}

.ant-slider-handle {
  background-color: #ec5118 !important;
  border: solid 2px#ad49345c !important;
}

.ant-slider-disabled .ant-slider-track {
  background-color: rgb(253, 203, 96) !important;
}

/* fix tabs */

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  right: 0;
  /* position: absolute; */
  bottom: 0px;
}

.conclusion {
  direction: rtl;
}

.ant-menu {
  background: transparent !important;
  border: none !important;
}

.img-about {
  right: -820px;
  top: -45px;
  width: 971px;
  height: 700px;
  object-fit: contain;
}

.qa .ant-collapse-item {
  margin-top: 29px;
}

.qa .ant-collapse-header {
  background: transparent !important;
  color: white !important;
}

.qa .ant-collapse-item {
  background: transparent !important;
  border: 5px solid #faf1ed !important;
  box-sizing: border-box;
  border-radius: 13px;
}

.max-screen {
  max-width: 1248px !important;
}

textarea.ant-input {
  border-radius: 20px;
}

.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content {
  position: relative !important;
}

.ant-tabs-tabpane.ant-tabs-tabpane-inactive {
  display: none;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  display: contents !important;
  right: 0 !important;
  position: absolute !important;
  bottom: 0px !important;
  height: 200px !important;
  width: 100% !important;
}

.ant-tabs-nav.ant-tabs-nav-animated {
  display: flex;
  justify-content: center;
}

.Breadcrumbs .ant-breadcrumb a:hover {
  color: #fd4d01;
}

.clear {
  clear: both;
}

.add-fix {
  bottom: 10px;
  right: 10px;
  position: fixed;
  width: 87px;
}

.ant-carousel {
  width: 100% !important;
}

.ant-carousel.slick-slide {
  display: inline-block !important;
}

.familyFt {
  font-family: FtJimboryRounded;
  font-size: 92px !important;
  line-height: 90px !important;
  font-weight: bold;
}

.responsiveVideo {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
}

.responsiveVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hamburg-menu-icon {
  border: 0px;
  padding: 0px;
  color: #fff;
  font-size: 24px;
  margin-right: 15px;
}

.hamburg-menu-icon:hover {
  color: #f88602;
}
.box-width {
  width: 35% !important;
}

// Page Header Classes
.page-header {
  margin-bottom: 15px;
  padding: 10px 0px;
}

.page-header .page-header-wrapper {
  padding: 0px 20px;
}

.page-header .page-header-wrapper .page-header-left h1 {
  font-size: 2.6em;
}

.page-header .page-header-wrapper .page-header-right {
  margin-left: auto;
  margin-right: 0px;
  position: absolute;
  right: 40px;
  width: 100%;
  justify-content: flex-end;
}

.page-header .page-header-wrapper .page-header-right .page-header-icon {
  margin-left: 15px;
  position: absolute;
  float: right;
  margin-right: 0;
  right: 0;
}

.page-header .page-header-wrapper .page-header-right .open-search-box {
  position: relative;
  width: calc(100% - 77px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 0px;
  z-index: 1;
  padding-right: 77px;
}

.page-header .page-header-wrapper .page-header-right .open-search-box input[type="text"] {
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 80px;
  background-color: #333333;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 600;
  color: #cccccc;
}

.page-header .page-header-wrapper .page-header-right .open-search-box .close-icon {
  width: 60px;
  height: 60px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #cc4c4c;
  cursor: pointer;
  margin-left: 10px;
  .anticon-close-circle {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.page-header .page-header-wrapper .page-header-right .page-header-icon-bg {
  width: 60px;
  height: 60px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  color: #ffffff;
  svg {
    color: #ffffff;
  }
  .anticon-search {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.page-header .page-header-wrapper .page-header-right .page-header-icon.search-big-icon {
  right: 75px;
}

.page-header .page-header-wrapper .page-header-right .page-header-icon.filter-big-icon {
  z-index: 3;
}

.inner-header-contain-wrapper {
  position: absolute;
  padding: 0px 20px;
  height: 100%;
  display: flex;
}

.inner-header-contain-wrapper h1 {
  margin: auto 0px;
  font-size: 2.6em;
  max-width: 360px;
  font-weight: bold;
}

.page-wrapper-log .page-header .page-header-wrapper .page-header-left h1 {
  font-size: 2.2em;
}

// Left Sidebar Classes
.left-sidebar-log {
  width: 290px;
}

.left-sidebar-log .ant-menu {
  padding-top: 20px;
  padding-bottom: 20px;
}

.left-sidebar-log .ant-menu .ant-menu-item {
  height: auto;
  line-height: normal;
  padding: 0px !important;
  margin: 0px;
}

.left-sidebar-log .ant-menu .ant-menu-item a {
  padding: 15px !important;
  color: #949494;
  font-size: 16px;
  font-weight: 600;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.left-sidebar-log .ant-menu .ant-menu-item a svg {
  font-size: 28px;
  margin-left: 5px;
  font-weight: bold;
  color: #949494;
  fill: #949494;
  max-height: 36px;
  max-width: 28px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.left-sidebar-log .ant-menu .ant-menu-item-selected,
.left-sidebar-log .ant-menu .ant-menu-item:active {
  background-color: transparent;
}

.left-sidebar-log .ant-menu .ant-menu-item:hover a,
.left-sidebar-log .ant-menu .ant-menu-item:focus a,
.left-sidebar-log .ant-menu .ant-menu-item-selected a,
.left-sidebar-log .ant-menu .ant-menu-item:active a {
  color: #fd4d01;
  background-color: transparent !important;
}

.left-sidebar-log .ant-menu .ant-menu-item:hover a svg,
.left-sidebar-log .ant-menu .ant-menu-item:focus a svg,
.left-sidebar-log .ant-menu .ant-menu-item-selected a svg,
.left-sidebar-log .ant-menu .ant-menu-item:active a svg {
  color: #fd4d01;
  fill: #fd4d01;
}

.left-sidebar-log .ant-menu .ant-menu-item-selected:after {
  border-right: 0px solid #f88602;
}

// Responsive Menu
.responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content {
  background-color: #333333;
}

.responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-grow: inherit;
  height: 100%;
}

.responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .menu-user-login {
  padding: 20px 20px 0px 20px;
}

.responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .ant-avatar {
  width: 80px;
  height: 80px;
}

.responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .ant-avatar-string {
  position: relative;
  transform: scale(1) translateX(-0%);
  left: 0%;
}

.responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .ant-avatar img {
  width: 80px;
  height: 80px;
}

.responsive-left-menu
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-body
  .menu-user-login
  .menu-user-details {
  margin-left: 30px;
}

.responsive-left-menu
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-body
  .menu-user-login
  .menu-user-details
  h3 {
  font-size: 27px;
}

.responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .logout-btn {
  height: auto !important;
  font-size: 16px;
  margin-left: 20px;
  background-color: #e90043;
  border: 0px;
}

.responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .logout-btn:hover {
  background-color: #f88602 !important;
  color: #fff;
}

.responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .logout-btn .anticon-logout {
  font-size: 18px;
  margin-right: 8px;
  margin-top: 3px;
}

// Custom Tab Design
.custom-tab .ant-tabs {
  color: rgba(255, 255, 255, 1) !important;
}

.custom-tab .ant-tabs .ant-tabs-nav-list .ant-tabs-tab {
  font-size: 16px;
  color: #cccccc !important;
  margin: 0 12% 0 0;
  padding: 28px 0;
}

.custom-tab .ant-tabs .ant-tabs-nav-list .ant-tabs-tab:hover {
  color: #f88602 !important;
}

.custom-tab .ant-tabs .ant-tabs-nav:before {
  border-bottom: 1px solid #666666;
}

.custom-tab .ant-tabs .ant-tabs-nav-list .ant-tabs-tab-active {
  font-size: 20px;
  font-weight: 600;
  color: #f88602 !important;
  border-bottom: 0px solid #666666;
}

.custom-tab .ant-tabs .ant-tabs-ink-bar {
  display: none;
}

.custom-tab .ant-tabs .ant-tabs-tabpane.ant-tabs-tabpane-active {
  position: relative !important;
  display: block !important;
  height: auto !important;
}

// Form Design Black
.form-design .form-design-black .ant-row {
  margin-bottom: 8px;
}

.form-design .form-design-black .form-col50 {
  width: 49%;
  margin-right: 2%;
}

.form-design .form-design-black .form-col50:last-child {
  margin-right: 0%;
}

.form-design .form-design-black .ant-row .ant-form-item-label {
  text-align: left;
}

.form-design .form-design-black .ant-row .ant-form-item-label label {
  font-size: 16px;
  color: #cccccc;
  font-weight: 500;
  margin-bottom: 0px;
}

.form-design .form-design-black .ant-row .ant-form-item-control-input-content input[type="text"],
.form-design .form-design-black .ant-row .ant-form-item-control-input-content input[type="email"],
.form-design .form-design-black .ant-row .ant-form-item-control-input-content input[type="password"],
.form-design .form-design-black .ant-row .ant-form-item-control-input-content select,
.form-design .form-design-black .ant-row .ant-form-item-control-input-content textarea,
.form-design .form-design-black .ant-row .ant-form-item-control-input-content .ant-select .ant-select-selector {
  background-color: transparent;

  padding: 4px 15px;
  width: 100%;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  color: #cccccc;
  min-height: 40px;
}

.form-design
  .form-design-black
  .ant-row
  .ant-form-item-control-input-content
  .input-group
  .ant-row:first-child
  .ant-form-item-control-input-content
  input[type="text"],
.form-design .form-design-black .ant-row .ant-form-item-control-input-content .price-input span input[type="text"] {
  border-radius: 6px 0px 0px 6px;
}

.form-design
  .form-design-black
  .ant-row
  .ant-form-item-control-input-content
  .input-group
  .ant-row:last-child
  .ant-select
  .ant-select-selector,
.form-design
  .form-design-black
  .ant-row
  .ant-form-item-control-input-content
  .price-input
  span
  .ant-select
  .ant-select-selector {
  border-radius: 0px 6px 6px 0px;
  border-left: 0px;
}

.form-design .form-design-black .ant-row .ant-form-item-control-input-content .price-input span {
  width: 100%;
}

.form-design .form-design-black .ant-row .ant-form-item-control-input-content .price-input span input[type="text"] {
  width: calc(100% - 90px) !important;
}

.form-design .form-design-black .ant-row .ant-form-item-control-input-content .price-input span .ant-select {
  width: 90px !important;
  margin: 0 !important;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
}

.form-design .form-design-black .ant-row .ant-form-item-control-input-content .ant-select .ant-select-arrow {
  width: 16px;
  height: 14px;
  font-size: 16px;
  margin-top: -8px;
  color: #ffffff;
}

.form-design .form-design-black .custom-upload-file .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}

.form-design .form-design-black .custom-upload-file .ant-form-item-control-input-content input[type="text"] {
  border-right: 0px;
  border-radius: 6px 0px 0px 6px;
}

.form-design .form-design-black .custom-upload-file .ant-form-item-control-input-content .btnStyle2 {
  border-radius: 0px 6px 6px 0px;
}

.form-design .form-design-black .form-btn-row {
  margin-top: 34px;
}

.form-design .form-design-black .form-btn-row .btn {
  min-height: 48px;
}

.form-design .form-design-black .ant-checkbox-wrapper {
  display: flex;
  color: #949494;
  font-size: 14px;
  align-items: center;
  margin: 10px 0px;
}

.form-design .form-design-black .ant-checkbox-wrapper .ant-radio {
  margin-right: 5px;
}

.form-design .form-design-black .ant-checkbox-wrapper .ant-checkbox {
  margin-top: 5px;
}

.form-design .form-design-black .ant-checkbox-wrapper .ant-checkbox-inner {
  background-color: #ffffff;
  width: 20px;
  height: 18px;
}

.form-design .form-design-black .ant-checkbox-wrapper .ant-checkbox-inner:after {
  left: 28%;
}

.form-design .form-design-black .ant-checkbox-wrapper .ant-checkbox-wrapper:hover .ant-checkbox,
.form-design .form-design-black .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner,
.form-design .form-design-black .ant-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #fd8e00;
}

.form-design .form-design-black .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner,
.form-design .form-design-black .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner,
.form-design .form-design-black .ant-checkbox-wrapper:focus .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #fd8e00;
  background-color: #fd8e00;
}

.form-design .form-design-black .ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner,
.form-design .form-design-black .ant-checkbox-wrapper:focus .ant-checkbox .ant-checkbox-inner {
  border-color: #fd8e00;
}

.form-design .form-design-black .ant-checkbox-wrapper .ant-checkbox-checked::after {
  border-color: #fd8e00;
}

.form-design .form-design-black .ant-form-item-control-input-content .btn-orange {
  min-width: 280px;
}

.form-design .form-design-black .ant-form-item-control-input-content .btn-orange:hover,
.form-design .form-design-black .ant-form-item-control-input-content .btn-orange:focus {
  background-color: #f05050;
  color: #fff;
}

// Form Design White
.form-design .form-design-white .ant-row {
  flex-direction: column;
  margin-bottom: 8px;
}

.form-design .form-design-white .ant-row .ant-form-item-label {
  text-align: left;
}

.form-design .form-design-white .ant-row .ant-form-item-label label {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 0px;
}

.form-design .form-design-white .ant-row .ant-form-item-control-input-content input[type="text"],
.form-design .form-design-white .ant-row .ant-form-item-control-input-content input[type="email"],
.form-design .form-design-white .ant-row .ant-form-item-control-input-content input[type="password"],
.form-design .form-design-white .ant-row .ant-form-item-control-input-content .ant-select .ant-select-selector {
  background-color: transparent;

  padding: 4px 15px;
  width: 100%;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  color: #949494;
  min-height: 40px;
}

.form-design .form-design-white .ant-row .ant-form-item-control-input-content .ant-select .ant-select-arrow {
  width: 16px;
  height: 14px;
  font-size: 16px;
  margin-top: -8px;
  color: #000000;
}

.form-design .form-design-white .form-btn-row {
  margin-top: 34px;
}

.form-design .form-design-white .form-btn-row .btn {
  min-height: 48px;
}

.form-design .form-design-white .ant-checkbox-wrapper {
  display: flex;
  color: #949494;
  font-size: 14px;
  align-items: center;
  margin: 10px 0px;
}

.form-design .form-design-white .ant-checkbox-wrapper .ant-radio {
  margin-right: 5px;
}

.form-design .form-design-white .ant-checkbox-wrapper .ant-checkbox {
  margin-top: 5px;
}

.form-design .form-design-white .ant-checkbox-wrapper .ant-checkbox-inner {
  background-color: #ffffff;
  width: 20px;
  height: 18px;
}

.form-design .form-design-white .ant-checkbox-wrapper .ant-checkbox-inner:after {
  left: 28%;
}

.form-design .form-design-white .ant-checkbox-wrapper .ant-checkbox-wrapper:hover .ant-checkbox,
.form-design .form-design-white .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner,
.form-design .form-design-white .ant-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #fd8e00;
}

.form-design .form-design-white .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner,
.form-design .form-design-white .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner,
.form-design .form-design-white .ant-checkbox-wrapper:focus .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #fd8e00;
  background-color: #fd8e00;
}

.form-design .form-design-white .ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner,
.form-design .form-design-white .ant-checkbox-wrapper:focus .ant-checkbox .ant-checkbox-inner {
  border-color: #fd8e00;
}

.form-design .form-design-white .ant-checkbox-wrapper .ant-checkbox-checked::after {
  border-color: #fd8e00;
}

.form-design .form-design-white .ant-form-item-control-input-content .btn-orange {
  min-width: 280px;
}

.form-design .form-design-white .ant-form-item-control-input-content .btn-orange:hover,
.form-design .form-design-white .ant-form-item-control-input-content .btn-orange:focus {
  background-color: #f05050;
  color: #fff;
}

.select-bank-account-items .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.select-bank-account-items .ant-form-item-label label {
  font-size: 22px !important;
  margin-bottom: 10px !important;
}

.select-bank-account-items .select-bank-account-item {
  width: 32%;
  margin-left: 2% !important;
  border: 1px solid #cccccc;
  font-weight: 500;
  margin-bottom: 0px !important;
  margin-right: 0px;
}

.select-bank-account-items .select-bank-account-item:first-child {
  margin-left: 0% !important;
}

.select-bank-account-items .select-bank-account-item .ant-radio-inner {
  width: 38px !important;
  height: 38px !important;
  border: 1px solid #f88602;
}

.select-bank-account-items .select-bank-account-item .ant-radio-inner:after {
  position: absolute;
  top: 26%;
  left: 32%;
  display: table;
  width: 11.714286px;
  height: 16.142857px;
  border: 3px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
  opacity: 0;
  transition:
    all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
    opacity 0.1s;
  content: "";
  background-color: transparent;
  border-radius: 0;
}

.select-bank-account-items .ant-radio-checked .ant-radio-inner:after {
  -webkit-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
  opacity: 1 !important;
  -webkit-transition: all 0.2s !important;
  transition: all 0.2s !important;
}

.select-bank-account-items .ant-radio-wrapper-checked .ant-radio-inner {
  background-color: #f88602;
}

.select-bank-account-items .select-bank-account-item .ant-checkbox-inner,
.select-bank-account-items .select-bank-account-item .ant-checkbox-inner:hover {
  width: 38px !important;
  height: 38px !important;
  border-radius: 50px;
  border: 1px solid #f88602;
}

.select-bank-account-items .ant-checkbox-wrapper .ant-checkbox-checked:after {
  border-radius: 50px;
}

.ant-picker-range {
  padding-right: 30px !important;
}

.select-bank-account-items .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
  width: 10.714286px;
  height: 16.142857px;
  left: 30%;
}

.drawer-custom-width .ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 70%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}

.drawer-custom-width .ant-drawer-content-wrapper {
  width: 100%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  height: auto !important;
}

.drawer-custom-width .ant-drawer-content-wrapper .ant-drawer-content {
  border-radius: 10px 10px 0px 0px;
}

.drawer-custom-width .ant-drawer-content-wrapper .ant-drawer-content .anticon-close {
  color: #e90043;
  font-weight: bold;
}

.filter-row {
  background: rgba(90, 92, 91, 0.22);
  padding: 20px 0px;
  z-index: 1;
}
.filter-grid {
  display: grid;
  grid: 45px/ 6% 9% 10% 10% 27%;
  grid-gap: 10px;
  @media screen and (max-width: 1000px) {
    grid: 45px/8% 9% 10% 10% 27%;
  }
}

.flex-1 .filter-grid.doctorfilter {
  grid: 45px/ 15% 17% 23% 27%;
}

.flex-2 .filter-grid.doctorfilter {
  grid: 45px/ 29% 45%;
}

.header-menu a {
  display: inline-block;
}
input.open-search-box {
  background: transparent;
  border-color: #fff;
  opacity: 1;
  appearance: none;
  outline: 0;
  padding: 5px;
  color: #fff;
}
.filter-select .search-icon {
  position: absolute;
  top: 11px;
  left: 10px;
}
.filter-select {
  position: relative;
}
.relative {
  position: relative;
}
.filter-select .search-icon svg path {
  fill: #fff;
}
.filter-select select {
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  padding: 5px;
}
.filter-select select option {
  color: #000;
}
.filter-row .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.filter-row .ant-form-item-control-input .ant-picker {
  color: #fff;
  opacity: 1;
  height: 45px;
  min-height: auto;
  padding: 8px 15px;
  border-radius: 4px;
}

.filter-row .ant-form-item-control-input input::-webkit-input-placeholder {
  color: #6d6d70 !important;
}
.filter-row .ant-input-search > .ant-input-group > .ant-input-group-addon {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
}
.filter-row span.ant-input-wrapper.ant-input-group {
  position: relative;
}
.filter-row .ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border: 0;
}
.filter-row .ant-form-item-control-input input {
  font-size: 18px;
}

.ant-select-dropdown {
  background: #fff;
  color: #fff;
  box-shadow: none;
  padding: 0;

  .rc-virtual-list {
    border: 1px solid black;
    box-sizing: border-box;
    width: 93%;
    margin: 10px auto;
    border-radius: 3px;
  }
}

.filter-row .ant-select-selector .ant-select-selection-item {
  font-size: 18px;
}
.filter-row .ant-form-item-control-input .ant-input-affix-wrapper {
  padding: 8px 15px;
  height: 45px;
}
.ant-select-dropdown .ant-select-item {
  border-bottom: 1px solid #fff;
  color: #342b2b;
  background: transparent;
  font-weight: normal;
  font-size: 14px;
  text-align: left !important;
}
.ant-btn-icon-only {
  font-size: 24px;
}
.ant-picker-dropdown .ant-picker-panel-container {
  background: #e9e9f0;
}

#control-ref .ant-picker {
  width: 100%;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
  color: #000aff;
  font-weight: bold;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none;
  border-radius: 100%;
  background: rgba(0, 10, 255, 0.22);
}
.ant-picker-today-btn {
  color: #000aff;
}
.table-general .ant-table-pagination-right {
  justify-content: center;
}
.table-general .ant-pagination {
  text-align: center;
}
.table-general .ant-table-pagination-right .ant-pagination-next,
.table-general .ant-pagination .ant-pagination-next {
  margin-right: 10px;
}
.table-general th {
  border-bottom: 1px solid #d0d1f0 !important;
  font-weight: 600 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.client-management-table th {
  border-bottom: none !important;
}

.ant-dropdown-menu-vertical .down-menu-link {
  font-size: 20px !important;
  color: #224653;
  border-top: 2px solid #000aff45;
  border-bottom: 2px solid transparent;
  padding: 12px 12px;
  text-decoration: none;
  background: #e9e9f0;
  width: 170%;
  font: normal normal 600 20px/24px;
}

.red-dark {
  color: #f82626;
}
.purple {
  color: #ac1afa;
}
.ant-dropdown-menu {
  box-shadow: none;
  padding: 0 !important;
}
.filter-grid .ant-form-item .ant-select,
.filter-grid .ant-form-item input {
  width: 100%;
}
.cart-list-item {
  background: rgba(193, 200, 216, 0.3);
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 12px;
  width: 49%;
}
.cart-list-item:nth-child(2n) {
  margin-right: auto;
}
.cart-item-action {
  margin-right: auto;
}
.text-blue {
  color: #000aff;
}
.cart-item-action {
  text-align: left;
}
.cart-item-action img {
  height: 15px;
  margin-right: 5px;
}
.marginRightauto {
  margin-right: auto;
}
.ant-btn {
  color: #000aff;
  border-color: #000aff;
  height: 52px;
  font-size: 18px;
}
.light-gray {
  background: #e9e9f0;
}
.radius-5 {
  border-radius: 5px;
}
.cart-item-details-main .ant-layout-sider {
  background: #ffffff;
  background: #ffffff;
  width: inherit !important;
  flex: inherit !important;
  min-width: inherit !important;
  max-width: 300px !important;
}
.cart-item-details-main .cart-item-action {
  text-align: center;
  width: 50%;
}
.cart-item-details-main .cart-item-details {
  width: 40%;
}
.border-line {
  height: 2px;
  width: 100%;
  opacity: 0.3;
}
.cart-item-details-main .light-gray {
  max-width: 300px;
  background: rgba(233, 233, 240, 0.32);
  border: 1px solid #c1c8d8;
}
.circle-logo {
  background: #c1c8d8;
  padding: 20px;
  width: 190px;
  height: 190px;
  border-radius: 100% !important;
  @media screen and (max-width: 1700px) {
    width: 150px;
    height: 150px;
  }
}
.circle-logo .b-white {
  border: 2px solid #707070;
  height: 136px;
  width: 136px;
  border-radius: 100% !important;
  @media screen and (max-width: 1700px) {
    width: 96px;
    height: 96px;
  }
}
.cart-item-action-box-list {
  text-align: center;
}
.cart-item-action-box-list .orange-bg {
  background: #e3c7ae;
}
.cart-item-action-box-list .orange-light {
  background: #f3e9de;
}
.rectangle-box {
  height: 160px;
  width: 160px;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 15px;
  color: #fff;
}
.cart-item-details-main section.ant-layout.ant-layout-has-sider {
  padding: 70px !important;
}
.cart-item-action-list .light-gray {
  margin: auto;
  text-align: right;
}
.cart-item-action-button button {
  min-width: 190px;
}
.listdoctorlist .ant-card-body {
  padding: 0;
}

.filter-grid.doctorfilter {
  grid: 45px/14% 40% 17% 27% 27;
}

.ant-row.ant-form-item-row {
  display: block !important;
}

.viewers-statistics-boxes-in span.anticon.anticon-delete {
  color: #c1c8d8;
  font-size: 18px;
}
.ant-checkbox-inner {
  background-color: #c1c8d8;
  border-color: #c1c8d8;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000aff;
  border-color: #000aff;
}
.ant-table {
  font-size: 15px;
}
.ant-table-thead > tr > th {
  color: #cbd1d9;
}
.ant-pagination-item a {
  background: #e9e9f0;
  border: 0;
  height: 100%;
  padding: 2px;
}
.ant-pagination-item {
  min-width: 26px !important;
  height: 28px !important;
  border: 0 !important;
  line-height: 1.7 !important;
}

.measurement .ant-select-item-option-content {
  height: 40px;
  font-size: 32px;
  line-height: 1.4;
}

.ant-form-item-control-input-content {
  direction: ltr;
}

#control-ref input {
  height: 50px;
}

.ant-pagination-next .ant-pagination-item-link {
  transform: rotate(-180deg);
}
.ant-pagination-prev .ant-pagination-item-link {
  transform: rotate(180deg);
}

li.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active{
  background: #ffffff!important;  
}
.ant-pagination-item-active,
.ant-pagination-item-active a {
  background: #000aff;
  font-weight: bold;
  color: #fff !important;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: transparent;
  border: 0;
  font-size: 18px;
}
.cart-list-item .br-gray {
  border-color: #707070 !important;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #000aff;
  border-color: #000aff;
}
.filter-grid .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #000aff;
}
.cart-item-details-main .ant-form-item .ant-input,
.addcustomer-box .ant-form-item .ant-input {
  width: 280px;
  background: rgba(233, 233, 240, 0.32);
  padding: 10px;
  border: 1px solid #c1c8d8;
  color: #646d82;
  font-size: 24px;
  line-height: 1;
}

.selectAddKlinik {
  .ant-select-selector {
    height: 50px !important;
  }
  .ant-form-item .ant-select {
    width: 277px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 280px;
    background: rgba(233, 233, 240, 0.32);
    padding: 10px;

    color: #646d82;
    font-size: 24px;
    line-height: 1;
  }
}

.cart-item-details-main .ant-form-item .ant-input::-webkit-input-placeholder,
.addcustomer-box .ant-form-item .ant-input::-webkit-input-placeholder {
  color: #646d82;
}
.addcustomer-box .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.addkliniksidelink {
  height: 315px;
  color: #646d82;
  display: flex;
  justify-content: center;
}
.dashboard-table-list .ant-table-row {
  background: rgba(193, 200, 216, 0.32);
  border-radius: 2px;
  margin-bottom: 5px;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: rgba(193, 200, 216, 0.32);
}

.ant-table-expanded-row .ant-table-cell {
  background: #f3f4f6 !important;
}

button {
  font: normal normal normal 30px/40px !important;
}
.table-general.benefitmanagemet-table .ant-pagination {
  text-align: right;
  justify-content: end;
}
.dashboard-table-list .ant-table table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.dashboard-table-list .ant-table table td {
  border-collapse: collapse;
  border-spacing: 0;
}
div#root {
  height: 100%;
}
.footer-link a {
  display: inline-block;
}
span.ant-select-arrow {
  float: left;
  order: 1;
  left: 11px;
  right: auto;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.listdoctor-table .ant-pagination-prev .ant-pagination-item-link,
.listdoctor-table .ant-pagination-next .ant-pagination-item-link {
  background: #fff;
}
.listdoctor-table .ant-pagination-item a {
  background: #fff;
}
.listdoctor-table .ant-pagination-item.ant-pagination-item-active a {
  background: #000aff;
}
.listdoctor-table .ant-pagination-item {
  min-width: 32px;
  height: 32px;
}
.table-general .ant-table-thead > tr > th:nth-child(2) {
  min-width: 110px;
}

.deleviry_history td.ant-table-cell {
  border-left: none;
  padding: 12px;
}

@media screen and (min-width: 768px) {
  .hide-desktop {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .page-wrapper,
  .page-header .page-header-wrapper,
  .inner-header .inner-header-contain-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
  .inner-header-contain-wrapper h1 {
    font-size: 3em;
    max-width: 410px;
  }
  .left-sidebar-log .ant-menu .ant-menu-item a svg {
    margin-left: 15px;
  }
  .hamburg-menu-icon {
    display: none;
  }
  .responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .logout-btn {
    margin-left: 30px;
  }
  .responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .menu-user-login {
    padding: 30px 20px 0px 30px;
  }
  .drawer-custom-width .ant-drawer-content-wrapper {
    max-width: 800px;
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .page-wrapper,
  .page-header .page-header-wrapper,
  .inner-header .inner-header-contain-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .inner-header-contain-wrapper h1 {
    font-size: 4em;
    max-width: 510px;
  }
  .left-sidebar-log .ant-menu .ant-menu-item a svg {
    margin-left: 25px;
  }
  .responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .logout-btn {
    margin-left: 40px;
  }
  .responsive-left-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .menu-user-login {
    padding: 40px 20px 0px 30px;
  }
}
@media only screen and (max-width: 1440px) {
  .box-width {
    width: 45% !important;
  }
  .f40 {
    font-size: 28px;
  }

  .filter-row .ant-select-selector .ant-select-selection-item {
    font-size: 16px;
  }
  .filter-row .ant-form-item-control-input input {
    font-size: 16px;
  }
  .filter-row .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .filter-row .ant-form-item-control-input .ant-picker {
    height: 40px;
    padding: 4px 15px;
  }
  .filter-row .ant-form-item-control-input .ant-input-affix-wrapper {
    padding: 4px 15px;
    height: 40px;
  }

  .header-menu a {
    font-size: 18px !important;
  }
  .main-header {
    height: 58px !important;
  }
  .f57 {
    font-size: 50px;
  }
  .dashboard-box-items .dashboard-box-item {
    padding-left: 36px !important;
    padding-bottom: 36px !important;
  }
  .padding-page2 {
    padding: 58px 0px 0px 0px;
  }

  .circle-logo .b-white {
    height: 70px;
    width: 70px;
  }
  .circle-logo {
    height: 98px;
    width: 98px;
  }
  .circle-logo .b-white .f30 {
    font-size: 20px !important;
  }
  .cart-item-details-main .light-gray {
    font-size: 18px;
  }

  .cart-item-details-main .ant-form-item .ant-input,
  .addcustomer-box .ant-form-item .ant-input {
    font-size: 18px;
  }
  .f30 {
    font-size: 22px;
  }
  .cart-item-details-main .ant-layout-sider {
    max-width: 160px !important;
  }
  .addkliniksidelink {
    height: 266px;
  }
}
@media screen and (max-width: 1024px) {
  .padding100 {
    padding: 50px 50px 100px 50px !important;
  }
  .box-width {
    width: 55% !important;
  }
  .f100 {
    font-size: 84px;
  }
  .f90 {
    font-size: 74px;
  }
  .f70 {
    font-size: 60px;
  }
  .f60 {
    font-size: 50px;
  }
  .f40 {
    font-size: 36px;
  }
  .f35 {
    font-size: 32px;
  }
  .f32 {
    font-size: 28px;
  }
  .f30 {
    font-size: 27px;
  }
  .f27 {
    font-size: 25px;
    line-height: normal;
  }

  .f24 {
    font-size: 22px;
    line-height: normal;
  }
  .f21 {
    font-size: 19px !important;
  }
  .f20 {
    font-size: 20px !important;
  }
  .f18 {
    font-size: 18px !important;
  }
  .f17 {
    font-size: 17px;
  }
  .f16 {
    font-size: 16px;
  }
  .f14 {
    font-size: 14px;
  }
  .f12 {
    font-size: 12px;
  }
  .f11 {
    font-size: 11px;
  }
  .f1 {
    font-size: 22px;
  }
  .f2 {
    font-size: 18px;
  }
  .f3 {
    font-size: 16px;
  }
  .f4 {
    font-size: 13px;
  }
  .f5 {
    font-size: 11px;
  }
  .f6 {
    font-size: 9px;
  }
  .page-header {
    margin: 10px 0px !important;
    padding: 5px 0px;
  }
  .page-header .page-header-wrapper .page-header-left h1 {
    font-size: 2.4em;
  }
  .page-wrapper-log .page-header .page-header-wrapper .page-header-left h1 {
    font-size: 2em;
  }
  .left-sidebar-log {
    display: none;
  }
  .responsive-left-menu .left-sidebar-log {
    display: block;
    width: 99.8%;
  }
  .responsive-left-menu .ant-drawer-content-wrapper {
    width: 75% !important;
    min-width: 300px;
  }
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item:hover,
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item-selected,
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item:active {
    background-color: #292929;
  }
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item a {
    color: #cccccc;
  }
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item a svg {
    color: #ffffff;
    fill: #ffffff;
  }
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item:hover a,
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item:focus a,
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item-selected a,
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item:active a {
    color: #fd8e00;
  }
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item:hover a svg,
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item:focus a svg,
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item-selected a svg,
  .responsive-left-menu .left-sidebar-log .ant-menu .ant-menu-item:active a svg {
    color: #fd8e00;
    fill: #fd8e00;
  }
  .page-header .page-header-wrapper .page-header-right {
    right: 20px;
  }
  .page-header .page-header-wrapper .page-header-right .open-search-box {
    width: calc(100% - 40px);
    padding-right: 62px;
  }
  .page-header .page-header-wrapper .page-header-right .open-search-box input[type="text"] {
    height: 50px;
    padding: 3px 20px;
    font-size: 16px;
  }
  .page-header .page-header-wrapper .page-header-right .open-search-box .close-icon {
    width: 50px;
    height: 50px;
    font-size: 18px;
    .anticon-close-circle {
      height: 50px;
    }
  }
  .page-header .page-header-wrapper .page-header-right .page-header-icon-bg {
    width: 50px;
    height: 50px;
    font-size: 18px;
    .anticon-search {
      width: 100%;
      height: 50px;
    }
  }
  .page-header .page-header-wrapper .page-header-right .page-header-icon.search-big-icon {
    right: 60px;
  }
  .dashboard-box-items .dashboard-box-item {
    padding-left: 26px;
    padding-bottom: 26px;
  }
  .viewers-statistics-boxes.grid-2 {
    padding-left: 0;
  }
  .five-boxes .page-wrapper {
    padding-left: 46px;
    padding-right: 46px;
  }
  .grid5 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .cart-item-details-main section.ant-layout.ant-layout-has-sider {
    padding: 50px !important;
  }
  .cart-item-details-main .ant-layout-sider {
    width: 150px !important;
    min-width: inherit !important;
    max-width: inherit !important;
    flex: none !important;
  }
  .cart-item-details-main .ant-form-item .ant-input,
  .addcustomer-box .ant-form-item .ant-input {
    font-size: 15px;
    width: 200px;
  }
  .f30 {
    font-size: 20px;
  }
  .f40 {
    font-size: 22px;
  }
}

.borderRight {
  border-right: 1px solid #c1c8d8;
}

@media screen and (max-width: 700px) {
  .menu {
    display: flex;
    overflow: scroll;
    height: 87vh;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 15px;
    margin-top: 10px;
    .dash-one,
    .dash-two,
    .dash-three {
      height: 5px;
      width: 40px;
      background-color: black;
    }
    .dash-one {
      margin-top: 10px;
    }
    .dash-two {
    }
    .dash-three {
      margin-bottom: 10px;
    }
    .cross-one,
    .cross-two {
      height: 5px;
      width: 40px;
      background-color: black;
    }
    .cross-one {
      transform: rotate(45deg);
      margin-top: 15px;
    }
    .cross-two {
      transform: rotate(-45deg);
      margin-top: -4px;
    }
  }

  .ant-popover {
        position: absolute;
        z-index: 99999;
        right: 5%!important;
        width: 60%;
    }


    .ant-popover-inner {
        background: #f9f9f9;
    }

  .desktop {
    display: none;
  }
  .map-wrapper {
    padding: 0px;

    .title {
      margin: 10px 23px;
    }
  }

  .sidebar {
    width: 100%;
    height: 100%;
    background-color: rgb(240, 240, 240);
    position: fixed;
    top: 53px;
    display: none;
    left: 0px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out 0s;
  }

  .sidebar.show {
    display: block;
    transform: translateX(0%);
  }

  .close_bar {
    display: none;
  }
}

.rtl {
  direction: rtl;
}

.bg-white {
  background: #fff;
}
